body {
  background-color: #b9ea75; /* Set the background color of the body */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

/* Set the default styles for images */
.App img {
  max-width: 100%; /* Ensure images don't exceed their container's width */
  height: auto; /* Maintain aspect ratio */
}

.App img.Hulk {
  height: auto; /* Reset height to auto for responsiveness */
  width: 80%; /* Set width to 80% for smaller screens */
  margin: 0 auto; /* Center the image */
  display: block; /* Ensure image is block-level element */
}

img.Tokens {
  width: 100%;
}

.bottom-container {
  position: relative; /* Ensure the bottom image and Telegram link stay positioned relative to each other */
}
.bottom-container video {
  width: 40%;
  height: 10%;
}
.telegram-link {
  position: absolute; /* Position the Telegram link absolutely over the bottom image */
  bottom: 70px; /* Adjust this value to position the link properly */
  right: 10%; /* Adjust this value to position the link properly */
  transform: translateX(-50%);
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 36px; /* Adjust font size for smaller screens */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .App img.Hulk {
    width: 100%; /* Set width to 100% for smaller screens */
  }

  .telegram-link {
    font-size: 14px;
    bottom: 10px; /* Adjust this value to position the link properly */
    right: -1%; /* Adjust font size for smaller screens */
  }
}
.App {
  text-align: center;
}

footer {
  background-color: transparent;
  padding: 20px 0;
}

.social-icons {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.social-icons a {
  color: #000;
  text-decoration: none;
  font-size: 24px;
}

.social-icons a:hover {
  color: #007bff; /* Change color on hover */
}
